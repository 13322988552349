import React, {Component} from 'react';
import axios from 'axios';

import {connect} from 'react-redux';
import { withRouter } from "react-router";
import { withFirebase } from '../../../firebase'

import Banners from './index';

class BannersContainer extends Component {

    constructor() {
        super();

        this.state = {
            preloader: false,
            spotId: null,
            allBanners: [],
            banners: [],
            images: [],
        }
    }

    componentDidMount(){
        this.getImages();
        this.getSpotId();
    }

    setImages = (images) =>  this.setState({ images });
    setBanners = (banners) =>  this.setState({ banners });

    getSpotId = () => {
        let key = this.props.location.pathname.split('/').find(el => parseInt(el) >= 0);
        const spotId = this.props.cities.find(city => city.key === key).spot_id;

        this.setState({ spotId });
    }

    getImages = () => {
        this.setState({ preloader: true });
        this.props.firebase.bd.ref('banners').on("value", (snapshot) => {
            let banners = snapshot.val();
            banners = banners ? banners : [];

            if (this.props.cities.length === 1) {
                banners.map((banner, index) => {
                    banners[index] = { ...banner, spotId: this.props.cities[0].spot_id };
                    console.log('banner', banner)
                });
            }

            this.setState({ banners, allBanners: banners, preloader: false });
        });
    }

    deleteBanner = ({ id, data, name }) => {
        console.log('delete banners!', data, id);
        let base_url = window.location.hostname == 'localhost:3000' ? 'localhost' : window.location.hostname;
        
        let filtredAllBanners = this.state.allBanners.filter(banner => banner.spotId !== this.state.spotId);
        let sendData = [ ...filtredAllBanners, ...data ];

        console.log('delete sendData', sendData);

        fetch(`https://e-admin.com.ua/photo/deleteBanner.php`, {
            method: 'POST',
            body: JSON.stringify({
                    id,
                    name,
                    login: this.props.login,
                    folder: 'loyalty-system'
                })
        })
            .then(response => {
                if (!response.ok && response.status !== 200) {
                    throw new Error('Ошибка запроса!');
                } else {
                    this.props.firebase.bd.ref(`banners`).set(sendData)
                        .then(res => {
                            this.setImages([]);
                            this.getImages();
                            setTimeout(() => alert(`Фото ${name} - было удалено!`), 100);
                        })
                        .catch(error => {
                            console.error(error)
                        });
                }
            })
            .catch(error => {
                console.log('error', error)
            });
    }

    saveText = (text, id) => {
        console.log('--- saveText --- text id', text, id); // text, id 
        try {
            this.props.firebase.bd.ref(`/banners/${id}/text`).set(text);
        } catch(err) {
            console.log('Ошибка записи текста!', err);
        }
    }

    sendData = (data) => {
        console.log('onSubmit data', data);

        let self = this;
        // let sendData = {};
        let isUpload = false;
        let isUpdate = false;
        let isDnD = false;

        for (let key in data) {
            if (data[key].upload) isUpload = true;
            if (data[key].update) isUpdate = true;
            if (data[key].dnd) isDnD = true;

            // sendData[key] = { 
            //     available: data[key].available, 
            //     text: !!data[key].text ? data[key].text : '' 
            // };
        }

        // console.log('data --->>>***', data)

        if (!isUpload && !isUpdate && !isDnD) {
            
            let banners = [...this.state.allBanners].filter(t => t.spotId !== this.state.spotId);

            banners = [ ...banners, ...data ]

            
            console.log('banners', banners);
            // console.log('data --->>>***', data); 

            self.props.firebase.bd.ref('/banners').set(banners)
                .then(res => {
                    console.log('success set data', banners);
                })
                .catch(error => {
                    console.error(error);
                });
        }

        if (isUpload) {
            console.log('--- uploading ---');

            let uploadData = [];
            
            for (let key in data) {
                let { available, spotId, order } = data[key];

                if (data[key].upload) {
                    uploadData.push({ 
                        available,
                        order,
                        spotId,  
                    });
                }
            }

            let isFetchOK = false;

            function fetch(file, key) {
                let base_url = window.location.hostname == 'localhost:3000' ? 'localhost' : window.location.hostname;
                console.log('file', file);
                return axios.post(`https://e-admin.com.ua/photo/index.php`, file)
                    .then(res => {
                        // console.log('res', res);
                        if (res && res.data && res.data.image_name) {
                            const namePhoto = res.data.image_name.split(' ')[0];
                            console.log('namePhoto', namePhoto);

                            if (namePhoto && self.props.login) {
                                isFetchOK = true;
                                uploadData[key].photo = `https://e-admin.com.ua/photo/photo/uploads/${self.props.login}/loyalty-system/${namePhoto}`
                            } else {
                                isFetchOK = false;
                                throw new Error('Неверные данные логина или названия изображения');
                            }
                        }

                    }).catch(error => {   
                        console.error('Ошибка загрузки изображения', error);
                        self.setState({preloader: false})
                    });
            }

            async function asyncGeneratorWay(callback) {
                async function* generateSequence() {
                    for (let key in uploadData) {
                        let item = data[key];

                        console.log('item -->>> ', item);
                        console.log('item.file -->>> ', item.file);
                        console.log('login -->>> ', self.props.login);
                        
                        if (item.file && self.props.login) {
                            let file = new FormData;
                            file.append('photo', item.file);
                            file.append('login', self.props.login);
                            file.append('folder', 'loyalty-system');

                            yield await fetch(file, key);
                        }
                    }
                }
                let generator = generateSequence();
                let result;
                while (!result || !result.done) {
                    result = await generator.next(result && result.done);
                }
                // callback(result.done);
                return { status: true };
            }

            let result = asyncGeneratorWay();

            result
                .then(response => {
                    if (!response || !response.status) {
                        throw new Error('Ошибка загрузки баннера');
                    }

                    if (response.status) {
                        try {
                            uploadData =  isFetchOK ?  [ ...this.state.banners, ...uploadData ] : this.state.banners;
                            console.log('uploadData --->>>', uploadData);

                            self.props.firebase.bd.ref('/banners').update(uploadData);
                            this.setState({ images: [] });
                        } catch (err) {
                            console.error('Ошибка записи в firebase', err);
                        }
                    }
                })
                .catch(error => {
                    alert('Ошибка при загрузке банеров', error)
                });
        }

        if (isUpdate) {
            console.log('--- updating ---');

            let updateData = {};

            for (let key in data) {
                let { available, photo } = data[key];

                if (data[key].update) {
                    updateData[key] = { 
                        available,  
                        photo,
                    };
                }
            }

            console.log('updateData -- >>', updateData);

            // try {
            //     self.props.firebase.bd.ref('/banners').update(updateData);
            // } catch (err) {
            //     console.error('Ошибка записи в firebase', err);
            // }
        }

        if (isDnD) {
            console.log('--- Drag and Drop ---');

            let dndData = [];
            let filtredAllBanners = [ ...this.state.allBanners ].filter(banner => banner.spotId !== this.state.spotId);

            for (let key in data) {
                let { available, photo, order, spotId } = data[key];

                if (data[key].dnd) {
                    dndData.push({ 
                        available,
                        order,
                        spotId,  
                        photo,
                    })
                }
            }

            let sendData = [ ...filtredAllBanners, ...dndData ];

            // console.log('sendData -- >>', sendData);

            self.props.firebase.bd.ref('/banners').set(sendData)
                .then(res => {
                    console.log('success set dnd data', sendData);
                })
                .catch(error => {
                    console.error(error);
                });
        }

        // console.log('=== sendData ===', sendData);
    }

    render() {
        return (
            <>
                <Banners 
                    getImages={this.getImages}
                    spotId={this.state.spotId}
                    onSubmit={this.sendData}
                    saveText={this.saveText}
                    deleteBanner={this.deleteBanner}
                    loadStatus={this.state.preloader}
                    banners={this.state.banners}
                    allBanners={this.state.allBanners}
                    images={this.state.images}
                    setImages={this.setImages}
                    banners={this.state.banners}
                    setBanners={this.setBanners}
                /> 
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        cities: state.shops.shops,
        login: state.auth.login,
    }
}

export default connect(mapStateToProps, null)(withRouter(withFirebase(BannersContainer)));