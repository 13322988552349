import React, {Component} from 'react';

import DisableApp from './disableApp';
import {connect} from 'react-redux';
import { withRouter } from "react-router";
import {updateKeyShop, editShops, updateSettings} from '../../reducers/shops';
import { withFirebase } from '../../firebase'

class DisableAppContainer extends Component{

    constructor(){
        super();

        this.state = {
            checked: true,
            keyShop: null
        }
    }

    componentDidMount(){
        this.props.updateKeyShop(this.props.match.params.city);
        this.setState({
            keyShop: this.props.match.params.city
        })
    }
    
    updateStatusApp = () => {
        let value = this.props.shops[this.props.match.params.city].orderAvailable ? false : true;
        this.props.editShops({
            key: this.props.match.params.city,
            params: 'orderAvailable', 
            value
        })
        this.props.firebase.bd
        .ref(`settings/City/${this.props.match.params.city}/`)
        .update({orderAvailable: value})
    }

    updateAlert = (data) => {
        if(data.text !== this.props.appSettings.closedSpotAlertDescription){
            this.props.updateSettings({params: 'closedSpotAlertDescription', value: data.text});
            this.props.firebase.bd
                .ref(`settings/appSettings/`)
                .update({closedSpotAlertDescription: data.text})
        }
    }



    render(){
        return(
            <>
            <DisableApp 
                        updateStatusApp={this.updateStatusApp}
                        keyShop={this.props.match.params.city}
                        closedAlert={this.props.closedAlert}
                        shops={this.props.shops[this.props.match.params.city]}
                        alert={this.props.appSettings.closedSpotAlertDescription}
                        editShops={this.editShops}
                        updateAlert={this.updateAlert}/> 
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        shops: state.shops.shops,
        appSettings: state.shops.appSettings
    }
}

export default connect(mapStateToProps, {updateKeyShop, 
                                        editShops, updateSettings})(withRouter(withFirebase(DisableAppContainer)));
