import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withFirebase } from '../../../firebase';

import Categories from './categories';

import {updateVisibility} from '../../../reducers/shops';

class CategoriesContainer extends Component{
    constructor(){
        super();

        this.state = {
            adminPermission: {},
            products: [],
            hiddenProducts: [],
            hiddenCategories: [],
            stopList: [],
            preloader: false,
            isActiveCategoriesSnackbar: false,
            isActiveMenusSnackbar: false,
            isActiveStopListSnackbar: false,
        }
    }

    componentDidMount(){
        this.getAdminPermission();
        this.getProducts();
    }

    getAdminPermission = () => {
        this.setState({  preloader: true });
        this.props.firebase.bd.ref('adminPermission').once('value', (snapshot) => {
            let adminPermission = snapshot.val();

            if (adminPermission) {
                this.setState({  adminPermission, preloader: false });
            }
            
            this.setState({ preloader: false });
        });
    }

    getProducts = () => {
        this.setState({  preloader: true });
        this.props.firebase.bd.ref('products').once('value', (snapshot) => {
            let products = snapshot.val();

            if (products) {
                this.setState({  products, preloader: false });
            }
            
            this.setState({ preloader: false });
        });
    }

    handleCategoriesSnackbar() {
        this.setState({  isActiveCategoriesSnackbar: true });

        setTimeout(() => {
            this.setState({ isActiveCategoriesSnackbar: false });
        }, 3000);
    }

    handleMenusSnackbar() {
        this.setState({  isActiveMenusSnackbar: true });

        setTimeout(() => {
            this.setState({ isActiveMenusSnackbar: false });
        }, 3000);
    }

    handleStopListSnackbar() {
        this.setState({  isActiveStopListSnackbar: true });

        setTimeout(() => {
            this.setState({ isActiveStopListSnackbar: false });
        }, 3000);
    }

    updateVisibility = (list, params) => {
        console.log('updateVisibility', list, params)

        if (params === 'hiddenCategories') {
            this.props.handleCategoriesMenu(true);
        } else {
           this.props.handleProductsMenu(true);
        }

        console.log('list, params === >>>', list, params);

        this.props.updateVisibility({list, params});
    }

    changeVisibleProduct = (products) => {

        this.setState({ preloader: true });
        this.props.firebase.bd.ref(`products`)
        .update(products)
        .then(res => {
            this.setState({
                preloader: false
            })
        });
    }

    saveData = (type) => {
        const isCategories = type === 'hiddenCategories' ? true : false;
        const isProducts = type === 'hiddenProducts' ? true : false;

        console.log('type', type)
        this.props.firebase.bd
        .ref(`settings/Category/${type}`)
        .set(this.props[type])
        .then(res => {
            this.setState({preloader: false});
            if (isCategories) {
                this.props.updateVisibility({list: this.props.hiddenCategories, params: 'hiddenCategories'});
                this.handleCategoriesSnackbar()
                this.props.handleCategoriesMenu(false);
                this.props.getDefaultHiddenCategories();
                
            } else if (isProducts) {
                this.props.updateVisibility({list: this.props.hiddenProducts, params: 'hiddenProducts'});
                this.handleMenusSnackbar();
                this.props.handleProductsMenu(false);
                this.props.getDefaultHiddenProducts();

            } else {
                // this.props.updateVisibility({list: this.props.stopList, params: 'stopList'});
                this.handleStopListSnackbar();
            }
        })
    }

    render() {
        // console.log('adminPermission', this.state.adminPermission);
        return(
            <>
                <Categories 
                    adminPermission={this.state.adminPermission}
                    hiddenCategories={this.props.hiddenCategories}
                    hiddenProducts={this.props.hiddenProducts}
                    stopList={this.props.stopList}
                    products={this.state.products}
                    changeVisibleProduct={this.changeVisibleProduct}
                    updateVisibility={this.updateVisibility}
                    loadStatus={this.state.preloader}
                    isActiveCategoriesSnackbar={this.state.isActiveCategoriesSnackbar}
                    isActiveMenusSnackbar={this.state.isActiveMenusSnackbar}
                    isActiveStopListSnackbar={this.state.isActiveStopListSnackbar}
                    saveData={this.saveData}
                />
            </>
                            
        )
    }
}
const mapStateToProps = (state) => {
    // console.log('state -->>>', state);
    return{
        shops: state.shops.shops,
        hiddenCategories: state.shops.hiddenCategories,
        hiddenProducts: state.shops.hiddenProducts,
        stopList: state.shops.stopList,

    }
}

export default connect(mapStateToProps, {updateVisibility})((withFirebase(CategoriesContainer)));
