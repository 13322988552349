/* eslint-disable eqeqeq */
import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Field, reduxForm} from 'redux-form';
import {required, numericality, addValidator, format} from 'redux-form-validators';

import Button from '../../../components/Button';
import Typography from '@material-ui/core/Typography';

import {InputField} from '../../../components/UIform/inputField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ModalComponent from '../../../components/ModalComponent';

import CustomInputTextarea from '../../../components/UIform/inputTextarea';

import CalendarPeriod from './CalendarPeriod';

const promocodesValidator = addValidator({
    defaultMessage: "Ошибка валидации",
    validator: function(options, value, allValues) {
      return (options.lowerCase ? /^[a-z0-9A-Z]+$/ : /^[a-z]+$/i).test(value)
    }
  })

let CreateModalPromocodesForm = (props) => {
    const classes = useStyles();

    const [openModal, setOpenModal] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [data, setData] = useState(null);
    const [checked, setChecked] = useState(null);
    const {handleSubmit} = props;

    const [count, setCount] = useState(1);

    const isPresent = count === 1;
    const isDiscount = count === 2;
    const isAmount = count === 3;

    // console.log('isPresent, isDiscount, isAmount', isPresent, isDiscount, isAmount);
    // console.log('count', count);
    // console.log('dataForm', props.dataForm);

    const handleChange = (e) => {
        const name = e.target.name;

        console.log('target', e.target);

        if (name == 'radioPresent' && count !== 1) {
            setCount(1);
        }

        if (name == 'radioDiscount' && count !== 2) {
            setCount(2);
        }

        if (name == 'radioAmount' && count !== 3) {
            setCount(3);
        }
    }

    const handleInputChange = (e) => {
        const {value} = e.target;

        setData({
            ...data,
            parametr: value,
        });

    }

    useEffect(() => {
        if (!checked) {
            if (data) {
                setChecked(data.useDiscountIds);
            }
        }
    }, [checked, data]);

    useEffect(() => {
        if (isLoading && isSubmit && !openModal) {
            const {onSubmit, handleClose, dataForm} = props;
            let arr = [];

            for (let key in dataForm) {
                arr.push(key);
            }

            // let newId = arr.length !== 0 ? +arr[arr.length - 1] + 1 : 0;
            let newId = data.id.replace(/ /g, '');

            console.log('newId', newId);

            onSubmit({
                ...dataForm,
                [newId]: {
                    ...data,
                    discountId: '',
                    useDiscountIds: checked ? checked : false
                },
            })
      

            handleClose()
        }
    
        if (isLoading && !isSubmit && !openModal) {
            console.log('Вы отменили');
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmit, isLoading, openModal]);

    const handleCloseModal = () => {
        setOpenModal(false);
        setIsSubmit(false);
    };

    const handleClickSubmit = () => {
        setOpenModal(false);
        setIsSubmit(true);
    };

    const submitForm = () => {
        const {valid} = props;

        if (valid) {
            setOpenModal(true);
            setIsLoading(true);
        }
    }

    const handleCheckboxChange = (e) => {
        const value = e.target.checked;

        if (checked !== value) {
            setChecked(value)
        }
    }

    const handleInputFieldChange = (e) => {
        const {name, value} = e.target;

        setData({
            ...data, 
            [name]: value,
            type: count,
        });
    }

    if (!data) {
        setData({
            ...data,
            available: true,
            type: count,
        });
    }

    // console.log('dataForm', props.dataForm);
    // console.log('data', data);

    return (
        <>
            <ModalComponent
                title="Подтвердите действие!"
                description="Вы подтверждаете сохранение изменений?" 
                open={openModal} 
                handleClose={handleCloseModal} 
                handleClickSubmit={handleClickSubmit} />
             <form onSubmit={handleSubmit} autoComplete='off'>
                <div className={classes.discountRadios}>
                    <div className={classes.discountInput} style={{ height: isPresent ? '100%' : '57px' }}>
                        <label htmlFor="radioPresent" className={classes.discountInputWrap}>
                            <Typography className={classes.discountTitle}>Подарок</Typography>
                            <Field 
                                id="radioPresent"
                                className={classes.discountRadio} 
                                name="radioPresent" 
                                component="input" 
                                type="radio" 
                                value={isPresent ? 'radioPresent': ''}
                                checked={isPresent}
                                onChange={handleChange} />
                        </label>
                        {isPresent && (
                            <div className={classes.discountHidden}>
                                <InputField 
                                    title="ID Товара"
                                    placeholder="Введите ID товара"
                                    name="present"
                                    type="text"
                                    onChange={handleInputChange}validate={[
                                        required({msg : 'Поле обязательно для заполнения'}),
                                      ]} />
                            </div>
                        )}
                    </div>
                    <div className={classes.discountInput} style={{ height: isDiscount ? '100%' : '57px' }}>
                        <label htmlFor="radioDiscount" className={classes.discountInputWrap}>
                            <Typography className={classes.discountTitle}>Скидка</Typography>
                            <Field 
                                id="radioDiscount"
                                className={classes.discountRadio} 
                                name="radioDiscount" 
                                component="input" 
                                type="radio" 
                                value={isDiscount ? 'radioDiscount': ''}
                                checked={isDiscount}
                                onChange={handleChange} />
                        </label>
                        {isDiscount && (
                            <div className={classes.discountHidden}>
                                <InputField 
                                    title="Процент скидки"
                                    placeholder="Введите процент скидки"
                                    name="discount"
                                    onChange={handleInputChange}
                                    validate={[
                                        required({msg : 'Поле обязательно для заполнения'}),
                                        numericality({
                                          int: true,
                                          '<=': 100,
                                          msg: { 
                                              '<=': 'Значение должно быть меньше или равно {count}',
                                              'int': 'Необходимо целые числа',
                                            },
                                        }),
                                      ]} />
                            </div>
                        )}
                    </div>
                    <div className={classes.discountInput} style={{ height: isAmount ? '100%' : '57px' }}>
                        <label htmlFor="radioAmount" className={classes.discountInputWrap}>
                            <Typography className={classes.discountTitle}>Сумма</Typography>
                            <Field 
                                id="radioAmount"
                                className={classes.discountRadio} 
                                name="radioAmount" 
                                component="input" 
                                type="radio"
                                value={isAmount ? 'radioAmount': ''}
                                checked={isAmount}
                                onChange={handleChange} />
                        </label>
                        {isAmount && (
                            <div className={classes.discountHidden}>
                                <InputField 
                                    title="Сумма скидки"
                                    placeholder="Введите сумму скидки"
                                    name="amount"
                                    onChange={handleInputChange}
                                    validate={[
                                        required({msg : 'Поле обязательно для заполнения'}),
                                        numericality({
                                          int: true,
                                          msg: { 
                                              'int': 'Необходимо целые числа',
                                            },
                                        }),
                                      ]} />
                            </div>
                        )}
                    </div>
                    <div className={classes.otherFields}>
                        <div className={classes.code}>
                            <CalendarPeriod 
                                data={data} 
                                setData={setData} />
                            <InputField 
                                title="Код промокода"
                                placeholder="Введите код промоакции"
                                className={classes.otherInput}
                                name="id"
                                type="text"
                                onChange={handleInputFieldChange}
                                validate={
                                    // eslint-disable-next-line no-sequences
                                    [required({msg : 'Поле обязательно для заполнения'}), format({ with: /^[a-z0-9A-Z]+$/, msg : 'Некорректные данные'})]
                                } 
                            />
                            <InputField 
                                title="Название"
                                placeholder="Введите название промоакции"
                                className={classes.otherInput}
                                name="title"
                                type="text"
                                onChange={handleInputFieldChange}
                                validMsg='Поле обязательно для заполнения' />
                            <InputField 
                                title="Описание"
                                component={CustomInputTextarea}
                                className={classes.textarea}
                                placeholder="Введите описание промоакции"
                                name="description"
                                onChange={handleInputFieldChange}
                                validMsg='Поле обязательно для заполнения' />
                        </div>
                        <FormControlLabel
                            control={ <Checkbox 
                                        className={classes.checkbox}
                                        checked={checked} 
                                        onChange={handleCheckboxChange} 
                                        name="checkedA"
                                        color="primary" />}
                                        label="Применять для акционных товаров"
                        />
                    </div>
                </div>

                <div className={classes.buttonWrapper}>
                    <Button fontSize={22} color={'#fff'} className={classes.button} 
                        text="Сохранить"
                        handleClick={handleSubmit(data => {
                            submitForm(data);
                        })} />
                </div>
            </form>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        width: '80%',
        maxWidth: 1057,
        padding: 24,
    },
    dialogTitle: {
        padding: 0,
        '& h2': {
            fontSize: 16,
            fontWeight: 'normal',
            lineHeight: '25px',
        }
    },
    iconsWrapper: {
        display: 'flex',
    },
    closeIcon: {
        marginRight: 16,
        color: '#476282',
        '&:hover': {
            color: 'black',
            cursor: 'pointer',
        }
    },
    buttonWrapper: {
        margin: '0 auto',
        marginTop: 30,
        textAlign: 'center',
    },
    discountRadios: {
        marginTop: 30,
        [theme.breakpoints.down('600')]: {
            marginTop: 16,
        },
    },
    discountInput: {
        display: 'flex',
        flexDirection: 'column',
        height: 57,
        width: '100%',
        padding: '0 16px 16px 16px',
        marginBottom: 16,
        boxSizing: 'border-box',

        boxShadow: '0px 14px 23px rgba(232, 228, 228, 0.5)',
        background: 'white',
        borderRadius: 5,
    },
    discountTitle: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '57px',
        color: 'black'
    },
    discountInputWrap: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
    },
    discountRadio: {
        margin: 0,
        cursor: 'pointer',
    },
    discountHidden: {
        width: 328,
        [theme.breakpoints.down('600')]: {
            width: '100%',
        },
    },
    otherFields: {

    },
    code: {
        
    },
    otherInput: {
        width: '100%',
        marginTop: 8,
        '& input': {
            width: '100%',
            height: 45,
            border: '1px solid #DADCE0',
            borderRadius: '5px',
            padding: '11px 50px 11px 16px',
            boxSizing: 'border-box',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            content: 'none',
        },
        '& .MuiInput-underline:after': {
            content: 'none',
        },
        '& .MuiInput-underline:before': {
            content: 'none',
        },
        '& .Mui-error input': {
            border: '1px solid red',
        }
    },
    textarea: {
        maxWidth: '327px',
        width: '100%',
        [theme.breakpoints.down('600')]: {
            maxWidth: '100%',
        },
        '& > div:before': {
            display: 'none'
        },
        '& > div': {
            // width: '327px',
            marginTop: 8,
            resize: 'none',
            // height: 103,
            border: '1px solid #DADCE0',
            borderRadius: '5px',
            padding: '11px 16px 11px 16px',
            boxSizing: 'border-box',
            fontSize: 16,
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            lineHeight: '24px',
            [theme.breakpoints.down('600')]: {
                width: '100%',
            }
        },
        '& > div:after': {
            display: 'none'
        },
        '& > div.Mui-error': {
            border: '1px solid red;'
        },
        '&::-webkit-input-placeholder': {
            color: '#DADCE0',
            fontWeight: 400,
        },
        '&::textarea:-moz-placeholder': {
            color: '#DADCE0',
            fontWeight: 400,
        },
        '&::textarea::-moz-placeholder': {
            color: '#DADCE0',
            fontWeight: 400,
        },
        '&::textarea:-ms-input-placeholder': {
            color: '#DADCE0',
            fontWeight: 400,
        },
        '&::textarea::placeholder': {
            color: '#DADCE0',
            fontWeight: 400,
        }
    },
    otherInputDescription: {
        width: '100%',
        marginTop: 8,
        '& input': {
            width: '100%',
            height: 103,
            border: '1px solid #DADCE0',
            borderRadius: '5px',
            padding: '11px 50px 11px 16px',
            boxSizing: 'border-box',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            content: 'none',
        },
        '& .MuiInput-underline:after': {
            content: 'none',
        },
        '& .MuiInput-underline:before': {
            content: 'none',
        },
        '& .Mui-error input': {
            border: '1px solid red',
        }
    },
    checkbox: {
        '&.MuiCheckbox-colorPrimary.Mui-checked': {
            color: '#326dff',
        },
        '&.MuiCheckbox-colorPrimary': {
            padding: 10,
        }
    },
}));


CreateModalPromocodesForm = reduxForm({
    form: 'CreatePromocodeForm', 
    enableReinitialize : true
})(CreateModalPromocodesForm);

export default CreateModalPromocodesForm;