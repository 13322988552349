/* eslint-disable eqeqeq */
import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Field, reduxForm} from 'redux-form';
import { connect } from 'react-redux';
import {required, numericality} from 'redux-form-validators';

import Button from '../../../../components/Button';
import Typography from '@material-ui/core/Typography';
import {InputField} from '../../../../components/UIform/inputField';
import ModalComponent from '../../../../components/ModalComponent';
import DialogComponent from '../../DialogComponent';

let FormDialog = (props) => {
    const classes = useStyles();

    const [openModal, setOpenModal] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    
    const {handleSubmit, count, setCount, getInitialValues} = props;

    const isPresent = count === 1;
    const isDiscount = count === 2;
    const isAmount = count === 3;

    console.log('count', count);

    const handleChange = (e) => {
        const name = e.target.name;

        console.log('name -->>', name)

        if (name == 'radioPresent' && count !== 1) setCount(1);
        if (name == 'radioDiscount' && count !== 2) setCount(2);
        if (name == 'radioAmount' && count !== 3) setCount(3);
    }

    const handleInputChange = (e) => {
        console.log('e --->>>', e);
        const val = e.target.value;
        const data = {};
        
        data['type'] = count;
        data['parametr'] = val;

        setData(data);
    }

    useEffect(() => {
        if (isLoading && isSubmit && !openModal) {
            props.onSubmit(data);
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmit, isLoading, openModal]);


    const handleClose = () => {
        setOpenModal(false);
        setIsSubmit(false);
    };

    const handleClickSubmit = () => {
        setOpenModal(false);
        setIsSubmit(true);
    };

    const submitForm = (data) => {
        const {valid} = props;

        if (valid) {
            setOpenModal(true);
            setIsLoading(true);
        }
    }

    const {initialValues} = getInitialValues(props);

    const handleInputChange1 = (e) => {
        console.log('e1', e)
    }

    return (
        <DialogComponent {...props}>
            <ModalComponent
                    title="Подтвердите действие!"
                    description="Вы подтверждаете сохранение изменений?" 
                    open={openModal} 
                    handleClose={handleClose} 
                    handleClickSubmit={handleClickSubmit} />
             <form className={classes.modalForm} onSubmit={handleSubmit} autoComplete='off' initialValues={initialValues}>
                <div className={classes.discountRadios}>
                    <div className={classes.discountInput} style={{ height: isPresent ? '100%' : '57px' }}>
                        <label htmlFor="radioPresent" className={classes.discountInputWrap}>
                            <Typography className={classes.discountTitle}>Подарок</Typography>
                            <Field 
                                id="radioPresent"
                                className={classes.discountRadio} 
                                name="radioPresent" 
                                component="input" 
                                type="radio" 
                                value={isPresent ? 'radioPresent': ''}
                                checked={isPresent}
                                onChange={handleChange} />
                        </label>
                        {isPresent && (
                            <div className={classes.discountHidden}>
                                <InputField 
                                    title="ID Товара"
                                    type="text"
                                    // component="input"
                                    placeholder="Введите ID товара"
                                    name="present"
                                    className={classes.inputField}
                                    onChange={handleInputChange}
                                    validMsg="Поле обязательно для заполнения" />
                            </div>
                        )}
                    </div>
                    <div className={classes.discountInput} style={{ height: isDiscount ? '100%' : '57px' }}>
                        <label htmlFor="radioDiscount" className={classes.discountInputWrap}>
                            <Typography className={classes.discountTitle}>Скидка</Typography>
                            <Field 
                                id="radioDiscount"
                                className={classes.discountRadio} 
                                name="radioDiscount" 
                                component="input" 
                                type="radio" 
                                value={isDiscount ? 'radioDiscount': ''}
                                checked={isDiscount}
                                onChange={handleChange} />
                        </label>
                        {isDiscount && (
                            <div className={classes.discountHidden}>
                                <InputField 
                                    title="Процент скидки"
                                    placeholder="Введите процент скидки"
                                    name="discount"
                                    className={classes.inputField}
                                    onChange={handleInputChange}
                                    validate={[
                                        required({msg : 'Поле обязательно для заполнения'}),
                                        numericality({
                                          int: true,
                                          '<=': 100,
                                          msg: { 
                                              '<=': 'Значение должно быть меньше или равно {count}',
                                              'int': 'Необходимо целые числа',
                                            },
                                        }),
                                      ]} />
                            </div>
                        )}
                    </div>
                    <div className={classes.discountInput} style={{ height: isAmount ? '100%' : '57px' }}>
                        <label htmlFor="radioAmount" className={classes.discountInputWrap}>
                            <Typography className={classes.discountTitle}>Сумма</Typography>
                            <Field 
                                id="radioAmount"
                                className={classes.discountRadio} 
                                name="radioAmount" 
                                component="input" 
                                type="radio"
                                value={isAmount ? 'radioAmount': ''}
                                checked={isAmount}
                                onChange={handleChange} />
                        </label>
                        {isAmount && (
                            <div className={classes.discountHidden}>
                                <InputField 
                                    title="Сумма скидки"
                                    placeholder="Введите сумму скидки"
                                    name="amount"
                                    onChange={handleInputChange}
                                    className={classes.inputField}
                                    validMsg="Введите сумму заказа" 
                                    validate={[
                                        required({msg : 'Поле обязательно для заполнения'}),
                                        numericality({
                                          int: true,
                                          msg: { 
                                              'int': 'Необходимо целые числа',
                                            },
                                        }),
                                      ]}/>
                            </div>
                        )}
                    </div>
                </div>

                <div className={classes.buttonWrapper}>
                    <Button className={classes.button} fontSize={22} color={'#fff'} className={classes.button} 
                        text="Сохранить"
                        handleClick={handleSubmit(data => {
                            submitForm(data)
                        })} />
                </div>
            </form>
        </DialogComponent>
    );
}

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        [theme.breakpoints.down('600')]: {
            background: 'red',
        },
    },
    buttonWrapper: {
        margin: '0 auto',
        marginTop: 30,
        textAlign: 'center',
    },
    button: {
        textTransform: 'none',
    },
    discountRadios: {
        marginTop: 30,
        [theme.breakpoints.down('600')]: {
            marginTop: 0,
        },
    },
    discountInput: {
        display: 'flex',
        flexDirection: 'column',
        height: 57,
        width: '100%',
        padding: '0 16px 16px 16px',
        marginBottom: 16,
        boxSizing: 'border-box',

        boxShadow: '0px 14px 23px rgba(232, 228, 228, 0.5)',
        background: 'white',
        borderRadius: 5,
        [theme.breakpoints.down('375')]: {
            padding: '0 8px 8px 8px',
        },
    },
    discountTitle: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '57px',
        color: 'black'
    },
    discountInputWrap: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
    },
    discountRadio: {
        margin: 0,
        cursor: 'pointer',
    },
    discountHidden: {
        width: 328,
        [theme.breakpoints.down('375')]: {
            width: '100%',
        },
    },
    inputField: {
        marginTop: 8,
        [theme.breakpoints.down('600')]: {
            width: '90%',
        },
        '& input': {
            width: 328,
            height: 45,
            border: '1px solid #DADCE0',
            borderRadius: '5px',
            padding: '11px 50px 11px 16px',
            boxSizing: 'border-box',
            [theme.breakpoints.down('600')]: {
                width: '100%',
                padding: '11px 16px 11px 16px',
            },
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            content: 'none',
        },
        '& .MuiInput-underline:after': {
            content: 'none',
        },
        '& .MuiInput-underline:before': {
            content: 'none',
        },
        '& .Mui-error input': {
            border: '1px solid red',
        }
    },
}));

const mapStateToProps = (state, props) => {
    const {getInitialValues} = props;
    const {initialValues} = getInitialValues(props);

    return { initialValues };
}

FormDialog = connect(mapStateToProps, null)(reduxForm({
    form: 'ModalForm', 
    enableReinitialize : true
})(FormDialog));

export default FormDialog;