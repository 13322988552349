import React, { Component } from 'react';
import { updatePushNotifications } from '../../reducers/shops';

import Push from './push';
import { withFirebase } from '../../firebase';
import AlertComponent from '../../components/AlertComponent';
import {connect} from 'react-redux';
import color from '@material-ui/core/colors/amber';

class PushContainer extends Component{

    constructor(){
        super();
        this.state = {
            loadStatus: false,
            phone: [],
            key: null,
            alertText: 'tres',
            alertShow: false,
            alertType: 'error',
            productPush: false, 
        }
    }

    componentDidMount() {
        this.getProductPushKey();
    }

    getProductPushKey = () => {
        this.setState({ loadStatus: true });
        this.props.firebase.bd.ref('adminPermission/productPush').on("value", (snapshot) => {
            let productPush = snapshot.val();

            this.setState({ productPush, loadStatus: false });
        });
    }

    sendPush = (data) => {
        const {cityId, phone, audience, text, product} = data;
        let topic = '/topics/';

        let self = this;

        let keyPush = this.props.notificationKey;

        let notification = {
            body: text,
            sound: 'default',
        }

        this.setState({
            loadStatus: true,
        })

        if (audience == 'favorite') {
            topic += phone.replace(/-/g, '');
        } else if (audience === 'city') {
            topic += cityId;
        } else if (audience === 'all') {
            topic += 'all';
        } else {
            topic += 'all';
        }
 
        let body = {
            to: topic,
            priority: "high",
            notification,
        }

        if (product) {
            body = {
                ...body,
                notification: {
                    ...notification,
                    click_action: `${this.props.bundleId}.FIREBASE_DATA`,
                    title: '',
                },
                data: {
                    product_id: product,
                    body: '',
                    title: '',
                }
            }
        }

        console.log('body --- >>>', body);

        console.log('body JSON =>', JSON.stringify(body));
        console.log('Authorization', 'key=' + keyPush);
        console.log('audience -->>>', audience);

        fetch('https://fcm.googleapis.com/fcm/send', {
            'method': 'POST',
            'headers': {
                'Authorization': 'key=' + keyPush,
                'Content-Type': 'application/json'
         },
            'body': JSON.stringify(body)
        }).then(function(response) {
            const dateTime = new Date().getTime();
            const timestamp = Math.floor(dateTime / 1000);
            var key = parseInt(timestamp/86400);

            if (audience == 'favorite') {
                self.setState({
                    alertText: 'Ваше уведомление успешно отправлено!',
                    alertShow: true,
                    alertType: 'success',
                    loadStatus: false
                })
            }

            if (audience == 'city') {
                const city = self.props.cities.find(t => t.spot_id === cityId);
                const cityKeyShop = city.key;

                let keyData = city.pushNotifications && city.pushNotifications[key] ? city.pushNotifications[key] : [];
                keyData = [...keyData, text];

                // console.log('url', `settings/City/${cityKeyShop}/pushNotifications/${key}`);
                // console.log('city', city);
                // console.log('keyData', keyData);

                self.props.firebase.bd.ref(`settings/City/${cityKeyShop}/pushNotifications/${key}`).update(keyData)
                .then(response => {
                    let pushNotificationsData = { ...city.pushNotifications };
                    pushNotificationsData[key] = keyData;

                    self.props.updatePushNotifications(cityKeyShop, pushNotificationsData);
                    self.setState({
                        alertText: 'Ваше уведомление успешно отправлено!',
                        alertShow: true,
                        alertType: 'success',
                        loadStatus: false
                    })
                })
                .catch(error => {
                    console.log(error);
                });
            }

            if (audience == 'all' || audience == undefined) {
                const cities = self.props.cities;
                // let citiesData = [ ...cities ];

                cities.forEach((city, index) => {
                    // const notificatonKey = city.pushNotifications && city.pushNotifications[key] ? city.pushNotifications[key] : [];
                    // let pushNotifications = city.pushNotifications ? city.pushNotifications : {};
                    // pushNotifications = { ...pushNotifications, [key]: [ ...notificatonKey, text ] };

                    let keyData = city.pushNotifications && city.pushNotifications[key] ? city.pushNotifications[key] : [];
                    keyData = [...keyData, text];

                    // citiesData[index] = {
                    //     ...citiesData[index],
                    //     pushNotifications: {
                    //         ...pushNotifications,
                    //         [key]: [ ...notificatonKey, text ]
                    //     }
                    // }

                    self.props.firebase.bd.ref(`settings/City/${index}/pushNotifications/${key}`).update(keyData)
                        .then(response => {
                            self.setState({
                                alertText: 'Ваше уведомление успешно отправлено!',
                                alertShow: true,
                                alertType: 'success',
                                loadStatus: false
                            })
                        })
                        .catch(error => {
                            console.log(error);
                        });
                });

                // console.log('citiesData Data ==>>>>', citiesData);
                // self.props.firebase.bd.ref(`settings/City/${cityKeyShop}/pushNotifications/${key}`).update(keyData)
                //     .then(response => {
                //         self.setState({
                //             alertText: 'Ваше уведомление успешно отправлено!',
                //             alertShow: true,
                //             alertType: 'success',
                //             loadStatus: false
                //         })
                //     })
                //     .catch(error => {
                //         console.log(error);
                //     });
            }

        }).catch(function(error) {
            console.log('error --->>> ', error);
            self.setState({
                alertText: 'При отправке произошла ошибка, попробуйте еще раз!',
                alertShow: true,
                alertType: 'error', 
                loadStatus: false
            })
        });
 
    }

    addPhone = (data) => {
        let phoneList = this.state.phone;
        phoneList.push(data.phone);
        this.setState({
            phone: phoneList,
        })
    }

    deletePhone = (key) => {
        let phoneList = this.state.phone;
        phoneList.splice(key, 1);
        this.setState({
            phone: phoneList
        })
    }

    hideAlert = () => {
        this.setState({
            alertShow: false
        })
    }

    render() {
        return(
            <>
            <Push onSubmit={this.sendPush}
                  productPush={this.state.productPush}
                  loadStatus={this.state.loadStatus}
                  phone={this.state.phone}
                  addPhone={this.addPhone}
                  deletePhone={this.deletePhone}/>
                  {this.state.alert}
            <AlertComponent type={this.state.alertType} 
                            text={this.state.alertText} 
                            status={this.state.alertShow}
                            hideAlert={this.hideAlert}
                            />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    console.log('state --- >>>', state);
    return {
        cities: state.shops.shops,
        notificationKey: state.shops.appSettings.notificationKey,
        bundleId: state.shops.appSettings.bundleId
    }
}

export default connect(mapStateToProps, { updatePushNotifications })(withFirebase(PushContainer));