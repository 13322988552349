import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { daysObj } from './other';
import { withRouter } from "react-router";
import { withFirebase } from '../../firebase';
import { useSelector, useDispatch } from 'react-redux';
import { addWorkingHours, addDataForm, addKeyShop, clearForm } from '../../reducers/workingHours';
import { updateWorkhours } from '../../reducers/shops';

import {updateIsDirtyWorktime} from '../../reducers/shops';
import WorkTime from './workTime';
import Loading from '../../components/Loading';

const WorkTimeContainer = (props) => {
    const dispatch = useDispatch();

    const workingHoursKeyShop = useSelector(state => state.workingHours.keyShop);
    const cities = useSelector(state => state.shops.shops);
    const workingHours = useSelector(state => state.workingHours.workingHours);
    const dataForm = useSelector(state => state.workingHours.dataForm);
    const orderTimeIntervalDelivery = useSelector(state => state.shops.appSettings.orderTimeIntervalDelivery);
    const keyShop = props.match.params.city;
    const city = cities[keyShop];
    // const cityId = city && city.spot_id;

    const [preloader, setPreloader] = useState(false);

    useEffect(() => {
        if (keyShop !== workingHoursKeyShop && city) {
            clearForm(dispatch);
            addKeyShop(dispatch, keyShop);

            if (city.workingHours) {
                addWorkingHours(dispatch, city.workingHours);
                getFormData(city.workingHours);
            }
        }
    }, [keyShop, workingHoursKeyShop, city]);

    const updateIsDirty = (data) => {
        const city = cities[keyShop];
        if (!city.isDirtyWorktime) updateIsDirtyWorktime(dispatch, data);
    }

    const getFormData = (workingHours) => {
        let data = {};
        const isOneWorkingHours = workingHours.length === 1;

        // console.log('--- workingHours ---', workingHours);

        if (!isOneWorkingHours) {
            for (let key in workingHours) {
                const item = workingHours[key];
                if (workingHours && item && item.dayNumber) {
                    const formatSubstractDeliveryEnd = moment(item.deliveryEnd.replace(':', ''), 'Hmm').subtract(+orderTimeIntervalDelivery, 'minute').format("HH:mm");
                    for (let index in daysObj) {
                        if (index == item.dayNumber) {
                            
                            data[daysObj[index]] = {
                                ...item,
                                deliveryEnd: item.begin === '00:00' && item.end === '00:00' ? '23:00' : formatSubstractDeliveryEnd,
                                checkbox: item.begin === '00:00' && item.end === '00:00' ? true : false,
                            };
                        }
                    } 
                }
            }
        }

        if (isOneWorkingHours) {
            for (let key in daysObj) {
                if (!data[daysObj[key]]) {
                    let { begin, end, deliveryEnd } = workingHours[0];
                    const formatSubstractDeliveryEnd = moment(deliveryEnd.replace(':', ''), 'Hmm').subtract(+orderTimeIntervalDelivery, 'minute').format("HH:mm");

                    data[daysObj[key]] = {
                        begin,
                        end,
                        dayNumber: Number(key),
                        deliveryEnd: formatSubstractDeliveryEnd,
                        checkbox: begin === '00:00' && end === '00:00' ? true : false,
                    };
                }
            }
        }

        if (Object.keys(data).length !== 0) {
            console.log('data --->>>', data);
            if (true) addDataForm(dispatch, data);
            if (true) addWorkingHours(dispatch, data);
            // if (dataForm.length === 0) addDataForm(dispatch, data);
            // if (workingHours.length === 0) addWorkingHours(dispatch, data);
        }
    }

    const saveWorkTime = (data) => {
        // console.log('Submit --- saveWorkTime --- ', data);

        let newWorkingHours =[];
        let isNotChanged = false;

        for (let id in data) {
            const deliveryEnd = data[id].deliveryEnd;
            const formatSubtractDeliveryEnd = moment(deliveryEnd, 'HH:mm').add(+orderTimeIntervalDelivery, 'minute').format('HH:mm');
            const formatDeliveryEnd = formatSubtractDeliveryEnd === '00:00' ? '24:00' : formatSubtractDeliveryEnd;

            newWorkingHours.push({
                ...data[id],
                deliveryEnd: data[id].begin === '00:00' && data[id].end === '00:00' ? '24:00' : formatDeliveryEnd,
            });
        }

        for (let key in dataForm) {
            let firstBegin = dataForm['Понедельник'].begin;
            let firstEnd = dataForm['Понедельник'].end;
            let firstDeliveryEnd = dataForm['Понедельник'].deliveryEnd;

            const dataFormBegin =  dataForm[key].begin.replace(':', '');
            const dataFormEnd =  dataForm[key].end.replace(':', '');
            const dataFormDeliveryEnd =  dataForm[key].deliveryEnd.replace(':', '');

            for (let index in data) {
                const dataDeliveryEnd =  data[index].deliveryEnd.replace(':', '');
                const dataBegin = data[index].begin.replace(':', '');
                const dataEnd = data[index].end.replace(':', '');

                console.log('deliveryEnd -->>>', dataDeliveryEnd, dataFormDeliveryEnd);

                if (dataBegin !== dataFormBegin || dataEnd !== dataFormEnd) {
                    if (dataDeliveryEnd !== '2400' && dataDeliveryEnd != '0000' && dataDeliveryEnd !== dataFormDeliveryEnd) {
                        isNotChanged = true;
                        console.log('--- 1 ---');
                    }
                }
            }

            if (
                firstBegin.replace(':', '') !== dataFormBegin || firstEnd.replace(':', '') !== dataFormEnd || 
                firstDeliveryEnd.replace(':', '') !== '2400' && firstDeliveryEnd.replace(':', '') !== '0000' &&
                firstDeliveryEnd.replace(':', '') !== dataFormDeliveryEnd
            ) {
                isNotChanged = true;
                console.log('--- 2 ---');
            }
        }

        console.log('isNotChanged', isNotChanged);
        console.log('dataForm', dataForm);
        console.log('data', data);
        // console.log('newWorkingHours', newWorkingHours);

        if (!isNotChanged) {
            let defaultWorkingHours = [];
            const { begin, end, deliveryEnd } = data[0];
            const isDeliveryEnd = deliveryEnd.replace(':', '') != end.replace(':', '');
            const timeEnd = moment(isDeliveryEnd ? deliveryEnd : end, 'kk:mm').add(+orderTimeIntervalDelivery, 'minute')
            const deliveryEndResult = timeEnd.format('kkmm') === '2400' ? '24:00' : timeEnd.format('HH:mm');
            const defaultWorkingData = { begin, end, deliveryEnd: deliveryEndResult };

            defaultWorkingHours.push(defaultWorkingData);

            console.log('defaultWorkingHours', defaultWorkingHours);

            setPreloader(true);
            props.firebase.bd.ref(`settings/City/${keyShop}/workingHours`).set(defaultWorkingHours)
                .then(res => {
                    let dataWorkingHours = {
                        key: keyShop,
                        workingHours: defaultWorkingHours
                    };

                    dispatch(updateWorkhours(dataWorkingHours));
                    getFormData(defaultWorkingHours);
                    setPreloader(false);
                })
                .catch(err => {
                    console.error(err);
                });
        }

        if (isNotChanged) {
            setPreloader(true);
            props.firebase.bd.ref(`settings/City/${keyShop}/workingHours`).update(newWorkingHours)
                .then(res => {
                    let dataWorkingHours = {
                        key: keyShop,
                        workingHours: newWorkingHours
                    };

                    dispatch(updateWorkhours(dataWorkingHours));
                    getFormData(newWorkingHours);
                    setPreloader(false);
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    const { isDirtyWorkingHours, handleIsDirtyWorkingHours } = props;

    if (!dataForm || !workingHours || props.preloader) {
        return (
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
            }}>
                <Loading style={{ margin: '16px auto' }} />
            </div>
        )
    }

    return (
        <WorkTime   
            onSubmit={saveWorkTime}
            updateIsDirty={updateIsDirty}
            isDirtyWorkingHours={isDirtyWorkingHours}
            handleIsDirtyWorkingHours={handleIsDirtyWorkingHours}
            orderTimeIntervalDelivery={orderTimeIntervalDelivery}
        /> 
    )
}

export default withRouter(withFirebase(WorkTimeContainer));