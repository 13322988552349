import React, {Component} from 'react';

import {connect} from 'react-redux';
import { withRouter } from "react-router";
import {createDelivery, updateKeyShop, editShops, updateSettings, updateDelivery, deleteDelivery, createDeliveryDefault} from '../../reducers/shops';
import { withFirebase } from '../../firebase'
import Delivery from './delivery';

import { checkFields } from './other';

class DeliveryContainer extends Component {

    constructor(){
        super();

        this.state = {
            checked: true,
            keyShop: null,
            preloader: false,
            delivery: null,
        }
    }

    componentDidMount() {
        this.props.updateKeyShop(this.props.match.params.city);
        this.setState({
            keyShop: this.props.match.params.city
        });
        this.getKeyDelivery()
    }

    getKeyDelivery = () => {
        this.props.firebase.bd.ref('adminPermission').on("value", (snapshot) => {
            let adminPermission = snapshot.val();
    
            this.setState({ delivery: adminPermission.delivery });
        });
    }

    deleteItems = (data, id) => {
        console.log('Delete data ids --->>> ', id);
       
        this.setState({preloader: true});
            this.props.updateDelivery({...data, key: this.state.key})
            this.props.firebase.bd
            .ref(`settings/City/${this.props.match.params.city}/deliveryZone`)
            .child(id).remove()
            .then(res => {
                this.setState({preloader: false})
        })
    }

    saveCity = (data) => {
        data = checkFields(data);
        const keyShop = this.props.match.params.city;
        console.log('Submit saveCity --->>> ', data);

        this.setState({preloader: true});
            this.props.createDeliveryDefault(data, keyShop);
            this.props.firebase.bd
            .ref(`settings/City/${keyShop}`).update(data)
                .then(res => {
                this.setState({preloader: false})
        });
    }

    deleteZone = (data, id) => {
        console.log('Delete Zone --->>> ', id);

        this.setState({preloader: true});
        this.props.deleteDelivery(this.state.keyShop, id);
            this.props.firebase.bd
            .ref(`settings/City/${this.props.match.params.city}/deliveryZone`)
            .set(data)
            .then(res => {
                this.setState({preloader: false})
        })
    }

    editZone = (data, id) => {
        data = checkFields(data);
        console.log('Submit editZone --->>> ', data);

        this.setState({preloader: true});
        const keys = { cityId: this.state.keyShop, zoneId: id };
        this.props.updateDelivery(data, keys);
        this.props.firebase.bd
        .ref(`settings/City/${this.props.match.params.city}/deliveryZone/${id}`)
        .update(data)
        .then(res => {
            this.setState({preloader: false})
        });
    }

    saveZones = (data, id) => {
        // data = checkFields(data);
        console.log('Submit saveZones --->>> ', data);

        this.setState({preloader: true});
            this.props.createDelivery(data, this.state.keyShop);
            this.props.firebase.bd
            .ref(`settings/City/${this.props.match.params.city}/deliveryZone`)
            .update(data)
            .then(res => {
                this.setState({preloader: false})
        })
    }

    render(){
        return(
            <>
            <Delivery 
                updateStatusApp={this.updateStatusApp}
                keyShop={this.props.match.params.city}
                closedAlert={this.props.closedAlert}
                shops={this.props.shops[this.props.match.params.city]}
                editShops={this.editShops}
                deleteZone={this.deleteZone}
                delivery={this.state.delivery}
                saveCity={this.saveCity}
                saveZones={this.saveZones}
                editZone={this.editZone}
                preloader={this.state.preloader}
                /> 
            </>
        )
    }
}

const mapStateToProps = (state) => {
    console.log('state ** -->>>', state);
    return{
        shops: state.shops.shops,
        appSettings: state.shops.appSettings
    }
}

export default connect(mapStateToProps, {updateKeyShop, 
                                        editShops, updateSettings, updateDelivery, createDelivery, deleteDelivery, createDeliveryDefault})(withRouter(withFirebase(DeliveryContainer)));
